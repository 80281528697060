<template>
  <div class="admin_wrapper" dir="ltr">
    <v-row no-gutters >
      <v-col cols="10" md="10">
        <router-view/>
      </v-col>
      <v-col cols="2" md="2">
        <Sidebar/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Sidebar from "@/components/admin/Sidebar";
export default {
  components: {Sidebar},
}
</script>