<template>
  <v-card
      class="mx-auto"
      height="100vh"
      width="256"
  >
    <v-navigation-drawer
        class="deep-purple accent-4"
        dark
        permanent

    >
      <v-list>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
        >

          <router-link class="link_holder" exact :to="{name: item.go}">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>

        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logOut">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import {log_out} from "@/api/general";

export default {
  data () {
    return {
      items: [
        { title: 'Users', icon: 'mdi-account', go: 'Users' },
        { title: 'Create Pages', icon: 'mdi-new-box', go: 'CreatePages'  },
        { title: 'Pages List', icon: 'mdi-clipboard-list', go: 'PagesList'  },
        { title: 'Change Password', icon: 'mdi-form-textbox-password', go: 'ChangePassword'  },
      ],
    }
  },
  methods: {
    logOut() {
      log_out();
    }
  }
}
</script>